import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  var settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    margin: 30,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      imageName: "iva",
      desc: `  Ochota a přístup Markéty k nám byla z našeho pohledu nadstandardní. Jako prvorodička jsem neměla konkrétní požadavky, takže při první schůzce jsme se pobavili, co bychom si od asistence s manželem představovali. Markéta nám poskytla kompletní informace, co vše nás může potkat v průběhu těhotenství a během porodu. Žádná otázka nebyla nikdy hloupá a Markéta nám vždy dobře poradila. Pro nás byl nejdůležitější samotný doprovod k porodu, kdy jsme se na ni mohli s čímkoliv obrátit. Během dne, kdy už se porod pomalu rozbíhal, jsme byli v kontaktu, večer nás přijela zkontrolovat a rozloučili jsme se nakonec až v průběhu noci po porodu dcerky. Porod proběhl přirozeně bez komplikací, hladce a rychle. Nedokážeme říci, jaký by byl porod bez ní, ale troufneme si tvrdit, že vše tak proběhlo, protože jsme měli u sebe někoho, komu jsme mohli plně důvěřovat a s kým jsme si lidsky rozuměli. Klid a jistotu nám dala hlavně její znalost a zkušenosti v oboru, nás samotných a našich požadavků a přání, které jsme si při jednotlivých setkáních upřesňovali. V šestinedělí jsme ještě využili možnost konzultací ohledně kojení, kdy nám v porodnici nikdo moc nepomohl a malá tak byla propuštěna na umělých příkrmech. I to se díky ní nakonec podařilo. Službu porodní asistentky bychom tímto chtěli obecně doporučit, a pokud si zvolíte právě Markétu, tak věříme, že budete spokojeni jako my. Ještě jednou ti moc děkujeme. `,
      reviewerName: "Iva a Vašek Lorencovi",
	  rating: "5.0",
    },
    {
      imageName: "tamca",
      desc: `  Markéta jako porodní asistentka mně, mému manželovi i našemu synovi poskytla veškerý servis, který bychom si jen mohli přát. Začalo to už předporodní přípravou. V kurzu jsme se dozvěděli vše potřebné, bez zamlčování možných rizik, ale zároveň bez zbytečného strašení. Díky praktickému nácviku můj muž přesně věděl, jak mi v těhotenství pomoct od bolesti, a oba jsme se cítili připraveni na narození našeho prvního dítěte. Markéta nám byla kdykoliv k dispozici na telefonu, a tak dokázala rozehnat jakékoliv obavy, které se před porodem objevily. Porod samotný byl rychlejší, než jsme očekávali, nicméně Markéta byla obrovskou oporou ve všech chvílích, které nás potkaly. Plně respektovala naše porodní přání a provázela nás celým průběhem s úctou a pochopením. Po návratu z porodnice byla opět k dispozici a pomohla mi s kojením, navázáním miminka do šátku, a především uklidnila všechny naše obavy, které jsme jako novopečení rodiče měli. Díky Markétě byl můj porod jedním z nejkrásnějších zážitků mého života, a pokud budeme mít to štěstí, druhé těhotenství a porod chceme sdílet znovu s ní.`,
      reviewerName: "Tamara Valešová",
	  rating: "5.0",
    },
	{
      imageName: "martina",
      desc: `  Dlho som premyslala nad tym, ci oslovit nejaku porodnu asistentku. Nakoniec vsak obavy z porodu (prvorodicka) zvitazili a ja som dala na doporucenie mojich kolegyn a oslovila Market. Mala som zmiesane pocity, nakolko sme sa vobec nepoznali a nevedela som velmi, co od toho ocakavat. Avsak uz prve online stretnutie s Market ma ukludnilo. Market bola super mila, usmievava, poskytla mi mnozstvo dolezitych informacii a ja som sa prestala porodu obavat. Po druhom online stretnuti nasledovala navsteva u nas, kde som sa este popytala na vsetky mozne detaily. Po cely cas mi bola plne k dispozicii a ja som sa na nu obracala s kazdou otazkou co ma napadla. Den porodu sme z vacsej casti stravili u nas doma. Do porodnice sme sli az ked to bolo nevyhnutne. Tam mi bola skutocnou oporou - hlavne psychickou. Co musim ocenit je jej pristup, ludskost a ochota. Aj po porode sa zaujimala ako o mna, tak aj o synceka. Velkou vyhodou Market je, ze je aj laktacnou poradkynou. A hoci mam uz mesiac po porode, stale sa na nu mozem s hocicim obratit, vzdy mi odpovie a poradi, viem, ze sa na nu mozem spolahnut. Dakujem Market!`,
      reviewerName: "Martina Sammali",
	  rating: "5.0",
    },
    {
      imageName: "petra",
      desc: `  S Marki jako porodni asistentkou jsem absolvovala oba dva svoje porody a jednim slovem jizda! Od zacatku kontrakci az po samotny porod jsem vedela, ze jsem v dobrych rukou a byla klidna. I to urcite prispelo k naprosto ukazkovym porodum bez komplikaci. Vsem, kteri premysleji nad pomoci pri porodu, vrele doporucuji! `,
      reviewerName: "Petra Kadrnková",
	  rating: "5.0",
    },
	{
      imageName: "klig",
      desc: `  Paní Markétu všem maminkám vřele doporučuji. Je velmi milá, vstřícná a ochotná. Vše skvěle vysvětlila, poradila a ukázala. Po celou dobu mne velmi podporovala a dodávala sebevědomí. Ochotně zodpověděla každou otázku, co se týká kojení, ale i celkové péče o miminko. Ocenila jsem, jak osobní konzultaci, tak konzultaci přes telefon. Její přístup byl velmi profesionální a lidský. Tímto ještě jednou moc děkuji!`,
      reviewerName: "Lucie Kligerová",
	  rating: "5.0",
    },
  ];

  return (
    <>
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div
            className="testmonial-box media"
            key={i}
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <div className="avatar">
              <img
                src={`img/testimonial/${val.imageName}.jpg`}
                alt="review comments"
              ></img>
            </div>

            {/* End avatar */}

            <div className="testmonial-text media-body">
              <p>{val.desc}</p>
              <div className="testmonial-info">
                <h6>{val.reviewerName}</h6>
                <div className="rating-value">
                  <i className="ti-star"></i>
                  <span>{val.rating}</span>
                </div>
              </div>
            </div>

            {/* End testimonial-text */}
          </div>
        ))}
      </Slider>
    </>
  );
}
