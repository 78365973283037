import React from "react";
import {
  FaFacebookF,
  FaInstagram,
} from "react-icons/fa";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/Porodn%C3%AD-asistentka-Mark%C3%A9ta-Han%C3%A1kov%C3%A1-104277065590775/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/ma_porodni_asistentka/" },
];

const Footer = () => {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-6 my-2">
          <div className="nav justify-content-center text-center text-md-left justify-content-md-start">
            {SocialShare.map((val, i) => (
              <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
                {val.Social}
              </a>
            ))}
          </div>
          {/* End .nav */}
        </div>
        {/* End .col */}

        <div className="col-md-6 my-2 text-center text-md-right">
          <p>
            © {new Date().getFullYear()} {" "}
              Bc. Markéta Hanáková | e-mail: info@maporodniasistentka.cz |&nbsp;
 
			  <a
              href="https://maporodniasistentka.cz/GDPR.pdf"
              target="_blank"
              rel="noreferrer"
            >
               Ochrana osobních údajů
            </a>
			 &nbsp;|&nbsp; 
			<a
              href="https://maporodniasistentka.cz/VOP.pdf"
              target="_blank"
              rel="noreferrer"
            >
               Všeobecné podmínky
            </a>
			 &nbsp;| Na webu jsou použity fotografie z 
			 <a
              href="https://www.freepik.com"
              target="_blank"
              rel="noreferrer"
            > FREEPIK.com
            </a>
          </p>
        </div>
        {/* End .col */}
      </div>
      {/* End .row */}
    </>
  );
};

export default Footer;
