import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const ServiceContent = [
  {
    icon: "ti-star",
    no: "01",
    service: "INDIVIDUÁLNÍ KONZULTACE",
    desc: `Máte otázky týkající se plánování rodičovství, těhotenství, porodu a další péče? Chcete znát další odborný názor na váš problém, vaše přání či myšlenku? Potřebujete podpořit v těžké životní situaci, která se týká vašeho reprodukčního zdraví a rodičovství?`,
    animationDelay: "",
  },
  {
    icon: "ti-book",
    no: "02",
    service: "KURZY",
    desc: `Nabízím kurzy, které jsou určeny přímo vám na míru. Kurzy jsou formou on-line prezentace nebo osobně. Aktuálně neposkytuji osobní skupinové lekce, pouze v online formě. Individuální kurzy jsou v obou formách stále.`,
    animationDelay: "150",
    },

  {
    icon: "ti-time",
    no: "03",
    service: "DOPROVOD K PORODU",
    desc: `Chcete se během porodu cítit bezpečně a opečovávaná? Chcete mít jistotu, že jde vše, jak má jít? Potřebujete psychickou i fyzickou podporu? Jsem vám schopna poskytnout komplexní péči během porodu a v případě Trutnovska i odvést porod v tamní porodnici.`,
    animationDelay: "300",
  },
  {
    icon: "ti-thumb-up",
    no: "04",
    service: "NÁVŠTĚVY V ŠESTINEDĚLÍ",
    desc: `Pokud máte po porodu problém, který potřebujete zkonzultovat, nebo jste po ambulantním porodu a chcete kontrolu sebe i miminka, případně potřebujete pomoci s péčí o dítě, můžeme se domluvit na návštěvě u vás doma.`,
    animationDelay: "",
  },
  {
    icon: "ti-heart",
    no: "05",
    service: "LAKTAČNÍ PORADENSTVÍ",
    desc: `Pro kojící ženy nabízím své služby laktační poradkyně.`,
    animationDelay: "150",
  },
  {
    icon: "ti-camera",
    no: "06",
    service: "DALŠÍ SLUŽBY",
    desc: `Pečující  služby o vás nebo vaše miminko. Fotografické služby (newborn, rodinná fotografie). `,
    animationDelay: "300",
  },
];

const ServiceTwoAnimation = () => {
  const [isOpen, setIsOpen] = useState(-1);
  return (
    <>
      <div className="row gy-4">
        {ServiceContent.map((val, i) => (
          <div
            className="col-sm-6 col-lg-4"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.animationDelay}
            onClick={() => setIsOpen(i)}
          >
            <div className="feature-box-02">
              <div className="icon">
                <i className={val.icon}></i>
              </div>
              <h6>
                <span>{val.no}.</span> {val.service}
              </h6>
              <h5>{val.title}</h5>
                    <p>{val.desc}</p>
                    <p><u>Detail služby</u></p>
            </div>
          </div>
        ))}
      </div>
      {/* Start Modal for Service */}
          {/* tohle kopirovat */}
          <Modal
              // tady je potreba menit isOpen=== 0..pocet - Služby 01
              isOpen={isOpen === 0}
              onRequestClose={() => setIsOpen(-1)}
              contentLabel="My dialog"
              className="custom-modal"
              overlayClassName="custom-overlay"
              closeTimeoutMS={500}
          >
              <div>
                  <button className="close-modal" onClick={() => setIsOpen(-1)}>
                      <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner">
                      <div className="scrollable">
                          <div className="blog-grid">
                              <div className="blog-img">
                                  <img src="img/blog/individualni_konzultace.jpg" alt="Individuální konzultace"></img>
                              </div>
                              {/* End blog-img */}
                              <article className="article">
                                  <div className="article-title">
                                      <h2>
                                          Individuální konzultace
                                      </h2>

                                  </div>
                                  {/* End .article-title */}

                                  <div className="article-content">
                                      <p>Máte otázky týkající se <span className="color-theme">plánování rodičovství, těhotenství, porodu a další péče</span>? Chcete znát další odborný názor na váš problém, vaše přání či myšlenku? Potřebujete podpořit v těžké životní situaci, která se týká vašeho reprodukčního zdraví a rodičovství?</p>

									  <p>Nabízím možnost individuálních konzultací, kde se budu snažit co nejlépe zodpovědět vaše otázky. Ráda vám poskytnu svůj nadhled a odborný názor na jakýkoliv váš problém nebo přání.</p>
                                    
									<p>Využijete-li konzultace v těhotenství, zkontroluji vás také dle svých zdravotnických kompetencí podobně jako v těhotenské poradně u gynekologa.</p>
									
									  <h4>Jak konzultace probíhají:</h4>
                                      <p>
                                          telefonická, online nebo osobní konzultace v závislosti na domluvě.
                                      </p>

                                      <h4>Cena:</h4>
                                      <p>on-line nebo telefonická konzultace</p>
                                      <b><ul><li>-> 300 Kč za každou započatou půlhodinu </li></ul>
                                      </b>

                                      <p>Osobní konzultace</p>
                                      <b><ul><li>-> 800 Kč/hodina + cestovné* </li></ul>
                                      </b>
									  
									  <p>Cestovné</p>
									  <b><ul><li>V rámci Trutnova a Žacléře zdarma.</li>
                                          <li>V rámci Brna zdarma. </li>
										  <li>-> jinak 9,- Kč / km </li></ul>
									  </b>
										  
                                  </div>
                                  {/* End article content */}
                              </article>
                              {/* End Article */}

                          </div>
                      </div>
                  </div>
              </div>
              {/* End modal box news */}
          </Modal>
          {/* End  Modal for Blog-3 */}
	  
      {/* tohle kopirovat */}
      <Modal
      // tady je potreba menit isOpen=== 0..pocet - Služby 02
        isOpen={isOpen === 1}
        onRequestClose={() => setIsOpen(-1)}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={() => setIsOpen(-1)}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/kurzy.jpg" alt="Kurzy"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
					<p>Nabízím kurzy, které jsou určeny přímo vám na míru. Kurzy jsou formou on-line prezentace nebo osobně. Aktuálně neposkytuji osobní skupinové lekce, pouze v online formě. Individuální kurzy jsou v obou formách stále.</p>
                    <h2>
                       Kurz "Cesta těhotenstvím, porodem a šestinedělím"
                    </h2>
           
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p>
                      Kurz <b>Cesta těhotenstvím, porodem a šestinedělím</b> je komplexní kurz, který vás provede všemi náležitostmi těhotenství, porodu,
                      šestinedělí i kojení.
                      Nastíní vám možné komplikace a zároveň jejich vhodné řešení. Dále se dozvíte, jaké byliny lze používat v těhotenství a kterým se vyvarovat.
                      Kurz se zabývá také masážemi nejen nastávající maminky, ale i miminka.
		      Poskytne vám důležité informace týkající se psychiky v těhotenství a v šestinedělí.
                      Dozvíte se jak se připravit na porodní bolest. Jak si ulevit nefarmakologickými metodami. A v neposlední řade příprava vašeho partnera na všechny tyto vaše životní fáze.
		      </p>
                      <p><span className="color-theme">Kurz vám zodpoví veškeré vaše otázky ohledně všech fází mateřství (šestinedělí, péče o dítě, kojení a převod na pevnou stravu, základy první pomoci).</span>
                    </p>
                    
                    <h4>Délka trvání kurzu:</h4>
                    <p>
                       3x 2 hodiny (v případě individuálních konzultací až 3 hodiny každý blok)
                    </p>

                    <h4>Cena:</h4>
                     <p>Individuální kurz</p>
                        <b><ul><li>-> online 3.000,- Kč </li>
                               <li>-> osobní 3.500,- Kč </li></ul>
                        </b>

                    <p>Skupinový kurz (max. 5 párů)</p>
                        <b><ul><li>-> pouze online 2.000,- Kč </li></ul>
						</b>
                  </div>
                  {/* End article content */}
                  <div className="article-title">
                      <h2>
                           Předporodní kurz
                      </h2>

                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                      <p>
                         <b>Předporodní kurz</b> vás provede koncem těhotenství, porodem a šestinedělím.</p>
                         <p>Zodpovím vám otázky jako <span className="color-theme">Kdy vyrazit do porodnice? Co s sebou? Co s sebou pro miminko?
                          Jak se připravit na porod? Jak pracovat s porodní bolestí? Jak správně při porodu dýchat a tlačit? Jak se rychleji zotavit po císařském řezu?
                          Jak začít kojit?</span></p>
                      

                      <h4>Délka trvání kurzu:</h4>
                      <p>
                          2x 2 hodiny (v případě individuálních konzultací až 2,5hodiny každý blok)
                      </p>

                      <h4>Cena:</h4>
                      <p>Individuální kurz</p>
                      <b><ul><li>-> online 2.000,- Kč </li>
                          <li>-> osobní 2.500,- Kč </li></ul>
                      </b>

                      <p>Skupinový kurz (max. 5 párů)</p>
                        <b><ul><li>-> pouze online 1 500,- Kč </li></ul>
						</b>
						
						
                  </div>
                  {/* End article content */}

                  <div className="article-title">
                    <h2>
                       Kurz pro ženy po císařském řezu
                    </h2>
           
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                      <p>
                         <b>Kurz pro ženy po císařském řezu</b> vám zodpoví následující otázky:</p>
                         <p><span className="color-theme">Jak se starat o svou jizvu? Můžu rodit vaginálně po císařském řezu? Jaká jsou specifika těhotenství a vaginálního porodu po císařském řezu? Kojení v šestinedělí po císařském řezu.</span>
			 </p>

                      <h4>Cena:</h4>
                      <p>Individuální kurz</p>
                      <b><ul><li>-> online 2.000,- Kč </li>
                          <li>-> osobní 2.500,- Kč </li></ul>
                      </b>

                  </div>
                  {/* End article content */}

                </article>
                {/* End Article */}

              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-3 */}

      {/* tohle kopirovat */}
      <Modal
      // tady je potreba menit isOpen=== 0..pocet - Služby 03
        isOpen={isOpen === 2}
        onRequestClose={() => setIsOpen(-1)}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={() => setIsOpen(-1)}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/doprovod_k_porodu.jpg" alt="Doprovod k porodu"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                       Doprovod k porodu s následnou péčí
                    </h2>
           
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p>
                      Chcete se během porodu cítit bezpečně a opečovávaná? Chcete mít jistotu, že jde vše, jak má jít? Potřebujete psychickou i fyzickou podporu?
						</p>
                      <p><span className="color-theme">Jsem vám schopna poskytnout komplexní péči během porodu a v případě Trutnovska i odvést porod v tamní porodnici..</span>
                    </p>
					
					<p>K porodu doprovázím pouze ženy, které u mne prošly některým z kurzů nebo konzultací v těhotenství. Důvodem je, že již znám jejich stav, jejich přání a byla mezi námi nastolena důvěra.</p>
                    
					<p>Služba obvykle zahrnuje rezervační poplatek, individuální konzultace 3x před porodem, pohotovost 3 týdny před a 2 týdny po termínu porodu, 2x návštěvu po porodu s laktačním poradenstvím dle potřeby, cestovné*. Obvyklá celková cena za doprovod bez předporodního kurzu je cca 21.000,-Kč (cena se může měnit dle využitých služeb jako držení pohotovosti nebo počet návštěv před a po porodu, cestovné) </p>
					
					<p>Berte prosím na vědomí, že <span className="color-theme">nechodím k porodům plánovaným doma!</span> Důvodem je, že vím, co se vše může během porodu a časně po porodu přihodit. V České republice momentálně bohužel není zajištěna dostatečně rychlá péče v případě vážných komplikací souvisejících s plánovanými porody doma a komunitní porodní asistentky jako profesionálky nemají zastání ve zdravotnictví ani v úřední či státní sféře.</p>
					
                    

                    <h4>Cena:</h4>
			   <p>Rezervační poplatek</p>
                        <b><ul><li>-> 6.000,- Kč </li></ul>
                        </b>
			  
                    <p>Doprovod u porodu</p>
                        <b><ul><li>-> 6.000,- Kč </li></ul>
                        </b>

					<p>Držení pohotovosti</p>
                        <b><ul><li>-> 1.000,- Kč / týden</li></ul>
						</b>
						
					<p>Návštěva po porodu</p>
                        <b><ul><li>-> 800,- Kč / návštěva</li></ul>
						</b>
						
					<p>Návštěva po ambulantním porodu</p>
                        <b><ul><li>-> 800,- Kč / návštěva (s odběrem z patičky = screening metabolických vad novorozence 1.000,- Kč)</li></ul>
						</b>

			  <p>Sepsání dokumentace v porodnici Trutnov</p>
                        <b><ul><li>-> 1.000,- Kč / návštěva (s odběrem z patičky = screening metabolických vad novorozence 1.000,- Kč)</li></ul>
						</b>
						
					<p>Cestovné</p>
					<b><ul><li>V rámci Trutnova a Žacléře zdarma.</li>
                        <li>V rámci Brna zdarma. </li>
					  <li>-> jinak 9,- Kč / km </li></ul>
					</b>
						
						
					<p>Nebo nabízím možnost si vybrat některý z cenově výhodnějších balíčků:</p>
					
					<h2>Celistvá péče se vzpomínkami</h2>
					<p>V ceně je <b>rezervační poplatek, komplexní kurz Cesta těhotenstvím, porodem a šestinedělím – osobní/online individuální kurz, 3x předporodní konzultace, těhotenské fotografování, v rámci Trutnova i sepsání dokumentace, pohotovost 5 týdnů, doprovod k porodu s následnou péčí a fotografováním porodu, 2x poporodní kontrola s laktačním poradenstvím, newborn nebo rodinné fotografování</b> (stylem, jaký si zvolíte dle nabídky fotografování)</p>
					<h4>Cena:</h4>
                        <b><ul><li>-> 25.900,- Kč *</li></ul>
                        </b>
						
					
					<h2>Celistvá péče</h2>
					<p>V ceně je <b>rezervační poplatek, komplexní kurz Cesta těhotenstvím, porodem a šestinedělím – osobní/online individuální kurz, 3x předporodní konzultace, pohotovost 5 týdnů, v rámci Trutnova i sepsání dokumentace, doprovod k porodu s následnou péčí, 2x poporodní kontrola s laktačním poradenstvím.</b></p>
					<h4>Cena:</h4>
                        <b><ul><li>-> 19.900,- Kč *</li></ul>
                        </b>

					<p>* neobsahuje případné cestovné</p>
					
                  </div>
                  {/* End article content */}
				  
                </article>
                {/* End Article */}

              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-3 */}

 

          {/* Start Modal for Service */}
          {/* tohle kopirovat */}
          <Modal
              // tady je potreba menit isOpen=== 0..pocet - Služby 04
              isOpen={isOpen === 3}
              onRequestClose={() => setIsOpen(-1)}
              contentLabel="My dialog"
              className="custom-modal"
              overlayClassName="custom-overlay"
              closeTimeoutMS={500}
          >
              <div>
                  <button className="close-modal" onClick={() => setIsOpen(-1)}>
                      <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner">
                      <div className="scrollable">
                          <div className="blog-grid">
                              <div className="blog-img">
                                  <img src="img/blog/navstevy.jpg" alt="Návštěvy v šestinedělí"></img>
                              </div>
                              {/* End blog-img */}
                              <article className="article">
                                  <div className="article-title">
                                      <h2>
                                          Návštěvy v šestinedělí
                                      </h2>

                                  </div>
                                  {/* End .article-title */}

                                  <div className="article-content">
                                      <p>
                                          Pokud máte po porodu <span className="color-theme">problém, který potřebujete zkonzultovat</span>, nebo jste po ambulantním porodu a <span className="color-theme">chcete kontrolu sebe i miminka</span>, případně <span className="color-theme">potřebujete pomoci s péčí o dítě</span>, můžeme se domluvit na návštěvě u vás doma.
									  </p>

                                      <h4>Cena:</h4>
                                     
                                      <b><ul><li>-> 800,- Kč / návštěva ( s odběrem z patičky = screening metabolických vad novorozence 1.000,- Kč)</li></ul>
                                      </b>
									  
									  <p>Cestovné</p>
									  <b><ul><li>V rámci Trutnova a Žacléře zdarma.</li>
                                          <li>V rámci Brna zdarma. </li>
										  <li>-> jinak 9,- Kč / km </li></ul>
                                      </b>

									  
                                  </div>
                                  {/* End article content */}
                              </article>
                              {/* End Article */}

                          </div>
                      </div>
                  </div>
              </div>
              {/* End modal box news */}
          </Modal>
          {/* End  Modal for Blog-3 */}

          {/* Start Modal for Service */}
          {/* tohle kopirovat */}
          <Modal
              // tady je potreba menit isOpen=== 0..pocet - Služby 05
              isOpen={isOpen === 4}
              onRequestClose={() => setIsOpen(-1)}
              contentLabel="My dialog"
              className="custom-modal"
              overlayClassName="custom-overlay"
              closeTimeoutMS={500}
          >
              <div>
                  <button className="close-modal" onClick={() => setIsOpen(-1)}>
                      <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner">
                      <div className="scrollable">
                          <div className="blog-grid">
                              <div className="blog-img">
                                  <img src="img/blog/laktacni.jpg" alt="Laktační poradenství"></img>
                              </div>
                              {/* End blog-img */}
                              <article className="article">
                                  <div className="article-title">
                                      <h2>
                                          Laktační poradenství
                                      </h2>

                                  </div>
                                  {/* End .article-title */}

                                  <div className="article-content">
                                      
                                      <p>Nabízím <span className="color-theme">konzultace po telefonu zdarma</span>. Nicméně ve většině případů (zejména při problémech s kojením) je nutná osobní návštěva k posouzení situace s následnou volbou vhodných technik a nástrojů k usnadnění a nastartování laktace.
                                      </p>

                                      <h4>Návštěva u kojící maminky:</h4>
                                  
                                      <b><ul><li>-> 600,- Kč/hod. (za každou další započatou půlhodinu 300,- Kč) + cestovné*. </li></ul>
                                      </b>
									  
									  <p>Cestovné</p>
									  <b><ul><li>V rámci Trutnova a Žacléře zdarma.</li>
                                          <li>V rámci Brna zdarma. </li>
										  <li>-> jinak 9,- Kč / km </li></ul>
                                      </b>

                                  </div>
                                  {/* End article content */}
                              </article>
                              {/* End Article */}

                          </div>
                      </div>
                  </div>
              </div>
              {/* End modal box news */}
          </Modal>
          {/* End  Modal for Blog-3 */}


          {/* Start Modal for Service */}
          {/* tohle kopirovat */}
          <Modal
              // tady je potreba menit isOpen=== 0..pocet - Služby 06
              isOpen={isOpen === 5}
              onRequestClose={() => setIsOpen(-1)}
              contentLabel="My dialog"
              className="custom-modal"
              overlayClassName="custom-overlay"
              closeTimeoutMS={500}
          >
              <div>
                  <button className="close-modal" onClick={() => setIsOpen(-1)}>
                      <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner">
                      <div className="scrollable">
                          <div className="blog-grid">
                              <div className="blog-img">
                                  <img src="img/blog/dalsi_sluzby.jpg" alt="Další nabízené služby"></img>
                              </div>
                              {/* End blog-img */}
                              <article className="article">
                                  <div className="article-title">
                                      <h2>
                                          Další nabízené služby
                                      </h2>

                                  </div>
                                  {/* End .article-title */}

                                  <div className="article-content">
                                      <p>
                                          Přejete si být během těhotenství nebo po porodu opečovávaná? Chcete na chvíli uniknout do svého světa plného klidu, příjemné vůně a dopřát si odpočinkovou péči o své tělo? Ráda byste se chtěla vědomě dotýkat svého miminka a poskytnout mu láskyplnou péči? Můžete si dopřát tyto služby přímo u vás doma. Třeba během individuální konzultace nebo během poporodní návštěvy.
                                      </p>
									  <p><span className="color-theme">Těhotenská nebo poporodní péče o ženu</span>
                                      </p>

                                      <h4>Cena:</h4>
                                      <b><ul><li>-> 1.000,- Kč </li></ul>
                                      </b>
									  
									  <p><span className="color-theme">Láskyplná péče o miminko s instruktáží </span>
                                      </p>

                                      <h4>Cena:</h4>
                                      <b><ul><li>-> 500,- Kč </li></ul>
                                      </b>

									  <p><span className="color-theme">Péče o jizvu po císařském řezu s instruktáží </span>
                                      </p>

                                      <h4>Cena:</h4>
                                      <b><ul><li>-> 600,- Kč </li></ul>
                                      </b>
									  
									  <p>Chcete si nechat zvěčnit vaše těhotenství, porod nebo novorozené miminko?</p>
									  <p>Moje fotografické služby naleznete na <a href="https://hanakova-foto.cz/" target="_blank" rel="noreferrer">www.hanakova-foto.cz</a></p>
										
									  <p><span className="color-theme">Fotografování za porodu</span> probíhá velice citlivou a vkusnou formou. Je to reportáž zachycující hlavně emoce spojené s příchodem na svět nového člena rodiny.</p>
                                     
									  <h4>Cena:</h4>
                                      <b><ul><li>-> 1.200,- Kč </li></ul>
                                      </b>
									 
                                  </div>
                                  {/* End article content */}
                              </article>
                              {/* End Article */}

                          </div>
                      </div>
                  </div>
              </div>
              {/* End modal box news */}
          </Modal>
          {/* End  Modal for Blog-3 */}

    </>
  );
};

export default ServiceTwoAnimation;
