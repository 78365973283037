import React from "react";
import { useForm } from "react-hook-form";

const Contact = () => {
  const {
    formState: { errors },
  } = useForm();

  return (
    <>
      <form action="https://formsubmit.co/info@maporodniasistentka.cz" method="POST">
        <div className="row">
          <div className="col-md-6">
            <div
              className="form-group"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <input
                name="jmeno"
                type="text"
                className="form-control"
                placeholder="Celé jméno"
                required 
				title="Prosím napiště své jméno"
			/>
             
              <label className="form-label">Jméno</label>
              {errors.jmeno && errors.jmeno.type === "required" && (
                <span className="invalid-feedback">Prosím napiště své jméno</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <div
              className="form-group"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="50"
            >
              <input
                name="email"
                type="email"
                className="form-control"
                placeholder="Emailová adresa"
                required
				pattern="\S+@\S+\.\S+"
				title="Vyplňte prosím vaši emailovou adresu"
              />
              <label className="form-label">Email</label>
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <div
              className="form-group"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <input
                name="predmet"
                type="text"
                className="form-control"
                placeholder="Předmět"
                required
				title="Prosím vyplňte předmět zprávy"
              />
			
              <label className="form-label">Předmět</label>
              {errors.predmet && (
                <span className="invalid-feedback">Prosím napiště předmět zprávy.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div
              className="form-group"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="150"
            >
              <textarea
                name="zprava"
                rows="4"
                className="form-control"
                placeholder="Napište zprávu"
                required
				title="Prosím vyplňte tělo zprávy"
              ></textarea>
              <label className="form-label">Zpráva</label>
              {errors.zprava && (
                <span className="invalid-feedback">Prosím vyplňte tělo zprávy.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div
            className="col-12"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
          >
            <div className="btn-bar">
              <button className="px-btn px-btn-theme">Poslat zprávu</button>
            </div>
          </div>
          {/* End .col-12 */}
			<input type="hidden" name="_captcha" value="false" />
			<input type="hidden" name="_next" value="https://email.hanakova-foto.cz/index.html" />
        </div>
      </form>
    </>
  );
};

export default Contact;