import React from "react";
import Header from "../../components/header/Header";
import Slider from "../../components/slider/SliderThreeAnimation";
import About from "../../components/about/AboutLightAnimation";
import Service from "../../components/service/ServiceTwoAnimation";
import Skills from "../../components/skills/SkillsAnimation";

import Testimonial from "../../components/testimonial/TestimonialAnimation";
import Blog from "../../components/blog/BlogAnimation";
import Contact from "../../components/contact/ContactAnimation";
import Footer from "../../components/footer/FooterAnimation";
import useDocumentTitle from "../../components/useDocumentTitle";

const HomeThree = () => {
  useDocumentTitle("Porodní asistentka pro Brno, Trutnov a okolí - Markéta Hanáková");
  return (
    <>
      <Header />
      {/* End Header Section */}

      <Slider />
      {/* End Banner Section */}

      {/* About Me */}
      <section id="about" className="section about-section after-left-section">
        <div className="container">
          <About />
        </div>
      </section>
      {/* End About Me */}

      {/* Services */}
      <section id="services" className="section services-section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Služby</h3>
              <p>
                 Poskytuji odborné poradenství, předporodní kurzy, doprovod při porodech, laktační poradenství. Své služby nabízím v Brně a jeho blízkém okolí a také v Trutnovském okrese. Vše je na domluvě.
              </p>
              <p>&nbsp;</p>
              <div className="sm-title2">
                  <h4 className="font-alt">Proč si vybrat právě mě ?</h4>
              </div>
                          <p>Poskytuji <span className="color-theme">komplexní a celistvé informace</span>. Ty jsou podávané v přirozeně na sebe navazujících oddílech. Obsahově dostanete <span className="color-theme">mnohem více informací než u podobně zaměřených kurzů</span>. Část kurzů je zaměřena na <span className="color-theme">individuální řešení problémů</span> nastávajících rodičů. Kurzy jsou sestavovány na základě <span className="color-theme">mých bohatých zkušeností a dlouholeté praxe</span>. Zároveň jsou doplněny informacemi z dalších oborů blízce souvisejících se zdravím a pohodou ženy (a nejen jí) v období <span className="color-theme">před těhotenstvím, v těhotenství, v období porodu, po porodu a během kojení</span>. Přednáším naživo <span className="color-theme">v online kurzech</span>, takže máte <span className="color-theme">možnost se přímo ptát</span> a objasnit si, co budete potřebovat.
              </p>
            </div>
          </div>
          {/* End .row */}
          <Service />
        </div>
      </section>
      {/* End Services */}

      {/*  Skills */}
      <section id="work" className="section skill-section">
        <div className="container">
          <Skills />
        </div>
      </section>
      {/* End Skills */}

      <div className="section testimonial">
        <div className="container">
          <Testimonial />
        </div>
      </div>
      {/* End testimonial */}

      {/* Blog */}
      <section id="blog" className="section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Články pro vás</h3>
              <p>
                V následujících článcích bych se s vámi chtěla ráda podělit o tom, kdo je porodní asistentka a proč jí chtít před, při a po porodu.
              </p>
            </div>
          </div>
          {/* End .row */}
          <Blog />
        </div>
      </section>
      {/*  End Blog */}

      {/* Contact */}
      <section id="contact" className="section after-left-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 my-3">
              <div className="contct-form">
                <div className="sm-title">
                  <h4 className="font-alt">Kontakt</h4>
                </div>
                {/* End .sm-title */}
                <Contact />
              </div>
            </div>
            {/* End .col */}

            <div className="col-lg-4 ml-auto my-3">
              <div className="contact-info">
                <div
                  className="sm-title"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <h4 className="font-alt">Napiště mi</h4>
                  <p>
                    Pokud máte jakýkoliv dotaz, můžete mi napsat na email níže, poslat mi dotaz skrz formulář nebo mi přímo zavolat.
                    Mile ráda vám váš dotaz zodpovím.
                  </p>
                </div>

                <div
                  className="media"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="50"
                >
                  <div className="icon">
                    <i className="ti-map"></i>
                  </div>
                  <span className="media-body">
                    Působiště: <br />
                    Brno a okolí, Trutnovsko
                  </span>
                </div>
                {/* End media */}

                <div
                  className="media"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <i className="ti-email"></i>
                  </div>
                  <span className="media-body">
                    Kontaktní e-mail: <br />
                    info@maporodniasistentka.cz
                  </span>
                </div>
                {/* End media */}

                <div
                  className="media"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="150"
                >
                  <div className="icon">
                    <i className="ti-mobile"></i>
                  </div>
                  <span className="media-body">
                    Telefonní kontakt: <br />
                    +420 724 549 232
                  </span>
                </div>
                {/* End media */}
              </div>
            </div>
            {/* End .col */}
          </div>
        </div>
      </section>
      {/* End Contact */}

      {/* Footer */}
      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/*  End Footer */}
    </>
  );
};

export default HomeThree;
