import React from "react";

const Skills = () => {
  return (
    <>
      <div className="row">
        <div
          className="col-lg-4 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
        >
          <div className="sm-title">
            <h4 className="font-alt">Odborné vzdělání & certifikáty</h4>
          </div>

          <ul className="aducation-box theme-bg">
			<li>
                <span className="dark-bg">2022</span>
                <h6>Péče o jizvu po císařském řezu a po epiziotomii</h6>
                <p>Vzdělávací kurz (Anna Kohutová)</p>
            </li>
	  		<li>
                <span className="dark-bg">2022</span>
                <h6>Diastáza</h6>
                <p>Certifikovaný kurz (Groofy)</p>
            </li>
	  		<li>
                <span className="dark-bg">2022</span>
                <h6>Pánevní dno</h6>
                <p>Certifikovaný kurz (Groofy)</p>
            </li>
	  		<li>
                <span className="dark-bg">2022</span>
                <h6>Resuscitace novorozence</h6>
                <p>Certifikovaný kurz + workshop (UNIPA / V. Bodnár, K. Zemánková)</p>
            </li>
	  		<li>
                <span className="dark-bg">2022</span>
                <h6>Natural ultrasound & Challenging birth</h6>
                <p>Vzdělávací kurz + workshop (Naoli Vinaver)</p>
            </li>
			<li>
                <span className="dark-bg">2022</span>
                <h6>Průvodkyně zdravým těhotenstvím a porodem</h6>
                <p>Vzdělávací kurz (Anna Kohutová)</p>
            </li>
			
            <li>
              <span className="dark-bg">2021</span>
              <h6>Baby masáž a těhotenská masáž</h6>
              <p>Certifikovaný kurz (Inprov sro)</p>
            </li>

            <li>
              <span className="dark-bg">2018</span>
              <h6>Laktační poradenství</h6>
              <p>Certifikovaný kurz (Laktační liga)</p>
            </li>

            <li>
                <span className="dark-bg">2015</span>
                <h6>Dystokie ramen při porodu, hodnocení STAN</h6>
                <p>Vzdělávací kurz (FN Brno)</p>
            </li>

            <li>
                <span className="dark-bg">2015</span>
                <h6>Zásady laktačního poradenství</h6>
                <p>Vzdělávací kurz (FN Brno)</p>
            </li>
			
            <li>
                <span className="dark-bg">2014</span>
                <h6>Kojení po císařském řezu a kojení v těhotenství</h6>
                <p>Vzdělávací kurz (FN Brno)</p>
            </li>
			
			<li>
                <span className="dark-bg">2013</span>
                <h6>Perinatologie</h6>
                <p>Vzdělávací kurz (99. doškolovací seminář)</p>
            </li>

			<li>
                <span className="dark-bg">2013</span>
                <h6>CTG</h6>
                <p>Certifikovaný kurz (FN Brno)</p>
            </li>
            
            <li>
                <span className="dark-bg">2012</span>
                <h6>Kojení předčasně narozeného novorozence</h6>
                <p>Vzdělávací kurz (Jack Newman)</p>
            </li>
			
			<li>
                <span className="dark-bg">2011</span>
                <h6>Modely péče v porodní asistenci</h6>
                <p>Vzdělávací kurz (ČKPA)</p>
            </li>
			
			<li>
                <span className="dark-bg">2010</span>
                <h6>Porodní asistence, Masarykova Univerzita Brno</h6>
                <p>Bakalářský titul Porodní asistence na Masarykově Univerzitě</p>
            </li>
			
          </ul>

          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        <div
          className="col-lg-4 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="sm-title">
            <h4 className="font-alt">Praxe</h4>
          </div>
          <ul className="aducation-box dark-bg">
            <li>
              <span className="theme-bg">2022-současnost</span>
              <h6>Nemocnice Trutnov</h6>
              <p>Gynekologicko-porodnická klinika</p>
            </li>

		    <li>
              <span className="theme-bg">2021-současnost</span>
              <h6>Členka UNIPA</h6>
              <p>Unie porodních asistentek - <a href="https://www.unipa.cz/profil/mark%c3%a9ta-han%c3%a1kov%c3%a1/" target="_blank" rel="noreferrer">profil na UNIPA</a></p>
            </li>

            <li>
              <span className="theme-bg">2010-2022</span>
              <h6>Fakultní nemocnice Brno</h6>
              <p>Gynekologicko-porodnická klinika</p>
            </li>

            <li>
              <span className="theme-bg">2010-2011</span>
              <h6>Soukromá gynekologická ordinace Brno</h6>
              <p>Praxe porodní asistentky</p>
            </li>

            <li>
                <span className="theme-bg">2001-2005</span>
                <h6>Mládež Českého červeného kříže</h6>
                <p>členka Mládeže Českého červeného kříže</p>
            </li>
          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        <div
          className="col-lg-4 ml-auto my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <div className="sm-title">
            <h4 className="font-alt">Přehled poskytovaných služeb</h4>
          </div>
          <div className="gray-bg skill-box">
            <div className="skill-lt">
              <h6>Kurzy</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 100 + "%" }}
                >
              
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <h6>Doprovody k porodům</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 0 + "%" }}
                >
               
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <h6>Laktační poradenství</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 100 + "%" }}
                >
               
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <h6>Individuální konzultace</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 0 + "%" }}
                >
				
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <h6>Ostatní služby (fotografie, těhotenská a poporodní péče)</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 100 + "%" }}
                >
				
                </div>
              </div>
            </div>
            {/* End skill-lt */}
          </div>
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default Skills;
