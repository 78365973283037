import React from "react";

const About = () => {
  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div
          className="col-lg-6 my-3"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div className="img-box">
            <img src="img/about/ja.jpg" alt="smiling a girl" />
          </div>
        </div>

        <div
          className="col-lg-6 my-3"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="typo-box about-me">
            <h3>Bc. Markéta Hanáková</h3>
            <h5>
              Vaše porodní asistentka
              pro <span className="color-theme">Brno a okolí</span> a <span className="color-theme">Trutnovsko</span>
            </h5>
            <p>
                          Jmenuji se Markéta Hanáková. Jsem porodní asistentka, profesionální rodinná fotografka, žena mého muže a máma svých dětí.
                          Velice zaujatá bylinkářka, zahradnice, milovnice hor, zvířat a hlubokých (nejen) animovaných filmů.
                          Empatie a péče o druhé je mou přirozenou součástí, která mne naplňuje a dává smysl mému životu.
            </p>
            <h5>
              <span className="color-theme">Profesní cesta</span>
            </h5>
            <p>
                          Roku 2010 jsem úspěšně ukončila studium Porodní asistence na LF MU v Brně. Od té doby jsem získávala praktické zkušenosti z oboru gynekologie,
                          a hlavně porodnictví v brněnské Fakultní nemocnici, v soukromé gynekologické ambulanci a také v poradenských centrech pro těhotné a matky s dětmi.
                          Absolvovala jsem a stále absolvuji velké množství kurzů a seminářů, které mi dávají nové poznatky z oborů
                          (gynekologie a porodnictví, pediatrie, psychologie, fyzioterapie, fytoterapie, fotografie), a ty jsou potom součástí mojí celkové péče.
                          Vzdělání a vybrané certifikáty k nahlédnutí <a href="#work">ZDE</a>.
            </p>
            <h5>
              <span className="color-theme">Osobní cesta</span>
            </h5>
            <p>
                          Cesta do zdravotnictví mne táhla od dětství. Péče o ostatní v těžších situacích, péče o jejich pohodu a následně i zdraví.
                          Tato cesta, i když byla klikatá, mne přivedla ke krásnému, mnohdy však i náročnému oboru ve zdravotnictví – k porodnictví. Po pár letech praxe v oboru a vlastnímu mateřství
                          jsem konečně pochopila, že tímto směrem mám opravdu jít. Péče o ženy v těhotenství, během porodu a po porodu je velice důležitá,
                          i když mnozí ji stále bagatelizují. Jakákoliv žena během těhotenství je nejistá, nedokáže zcela dobře určit,
                          co je dobré a co naopak už ne, snaží se dělat to nejlepší pro dítě i sebe, vyhledává informace, kterých je v dnešní době mnoho.
                          Ovšem ne mnoho správných a vhodných pro danou těhotnou ženu, rodičku a šestinedělku.
                          Během porodu potřebuje podporu, potřebuje útěchu, potřebuje sebejistotu, potřebuje neztrácet sebe sama. A po porodu?
                          Nové miminko je něco nového, má své potřeby, zasahuje do posud zajetého režimu. Novorozená maminka také potřebuje péči,
                          potřebuje svou sebejistotu, potřebuje oporu a ujištění. Taktéž pomoc při kojení a pomoc s péčí o své miminko.
                          A co teprve „novopečený“ tatínek? Toto vše dokáži naplnit a péče o novou rodinu mne velice naplňuje.
                          Proto být porodní asistentkou, pomáhat i v těch nejtěžších chvílích, ať už jsou radostné nebo velice smutné, se stalo mým osudem, mým posláním. 
                          
            </p>
            <p>              Sama jsem si prošla dvěma těhotenstvími ukončenými ne moc zdárnými porody. 
                          Odborná péče při porodu a ochrana zájmů nastávající maminky je pro mne proto velice důležitá. 
                          Samozřejmě také následná péče během šestinedělí a kojení.
             </p>


            <div className="row about-list">
              <div className="col-md-6">
                <div className="media">
                  <label>Působiště</label>
                  <p>Brno a blízké okolí, Trutnovsko</p>
                </div>
              </div>
              {/* End .col */}

              <div className="col-md-6">
                <div className="media">
                  <label>E-mail</label>
                  <p>info@maporodniasistentka.cz</p>
                </div>
                <div className="media">
                  <label>Telefon</label>
                  <p>+420-724 549 232</p>
                </div>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}

          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
