import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const Blog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }
  function toggleModalFour() {
    setIsOpen4(!isOpen4);
  }
  function toggleModalFive() {
    setIsOpen5(!isOpen5);
  }

  return (
    <>
      <div className="row">
        <div
          className="col-lg-4 col-md-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
        >
          <div className="blog-post" onClick={toggleModalOne}>
            <div className="blog-img">

              <img src="img/blog/proc_chtit_PA.jpg" alt="Proč chtít péči porodní asistentky"></img>
            </div>
            {/* End blog-img */}

            <div className="blog-info">
              <h6>Proč chtít péči porodní asistentky</h6>
              <p>
                Celistvá individuální péče porodní asistentky je poměrně dražší záležitost. Obzvlášť v dnešní těžké době. Proč ji tedy chtít využít?
              </p>
              <div className="btn-bar">Přečíst více</div>{" "}
            </div>

            {/* End blog-info */}
          </div>
          {/* End blog-post */}
        </div>
        {/* End .col for blog-1 */}



        <div
          className="col-lg-4 col-md-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="blog-post" onClick={toggleModalTwo}>
            <div className="blog-img">

              <img src="img/blog/kdo_je_PA_orez.jpg" alt="Kdo je porodní asistentka"></img>
            </div>
            {/* End blog-img */}

			<div className="blog-info">
              <h6>Kdo je porodní asistentka</h6>
              <p>
                Stále ještě potkávám lidi, kteří mají mylnou představu o práci porodní asistentky. Spousta z nich si myslí, že je pouze “sestřičkou”, pomáhající u porodu lékaři. Že vlastně nevykonává žádnou odbornou práci, která by byla něčím odlišná od ostatních “nemocničních sester”.
				Opak je ale pravdou. 
              </p>
              <div className="btn-bar">Přečíst více</div>
            </div>
			
            {/* End blog-info */}
          </div>
          {/* End blog-post */}
        </div>
        {/* End .col for blog-2 */}



        <div
          className="col-lg-4 col-md-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="blog-post" onClick={toggleModalThree}>
            <div className="blog-img">

              <img src="img/blog/moderni_porodnictvi.jpg" alt="Moderní porodnictví"></img>
            </div>
            {/* End blog-img */}

			<div className="blog-info">
              <h6>Moderní porodnictví</h6>
              <p>
                Moderní porodnictví začíná u diagnostiky těhotenství a končí (možná se budete divit) propuštěním z porodnice. Zahrnuje veškeré těhotenské screeningy, těhotenské poradny, řešení rizikových stavů, porod samotný i časné poporodní období. 
              </p>
              <div className="btn-bar">Přečíst více</div>
            </div>
			
            {/* End blog-info */}
          </div>
          {/* End blog-post */}
        </div>
        {/* End .col for blog-3 */}



        <div
          className="col-lg-4 col-md-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="blog-post" onClick={toggleModalFour}>
            <div className="blog-img">

              <img src="img/blog/tradicni_porodnictvi.jpg" alt="Tradiční porodnictví"></img>
            </div>
            {/* End blog-img */}

			<div className="blog-info">
              <h6>Tradiční porodnictví</h6>
              <p>
                Pod pojmem tradiční porodnictví si můžeme představit spousty věcí, například jak se rodilo v dávné minulosti doma. Asistence rodinných příslušníků u porodu a pomoc zkušených žen, které samy porodily děti. Pomoc porodních bab, které přejímaly své umění od starších, zkušených žen, později učené lékaři.
			  </p>
              <div className="btn-bar">Přečíst více</div>
            </div>
			
            {/* End blog-info */}
          </div>
          {/* End blog-post */}
        </div>
        {/* End .col for blog-4 */}



        <div
          className="col-lg-4 col-md-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="blog-post" onClick={toggleModalFive}>
            <div className="blog-img">

              <img src="img/blog/tradicni_vs_moderni.jpg" alt="Tradiční vs. moderní porodnictví"></img>
            </div>
            {/* End blog-img */}

			<div className="blog-info">
              <h6>Tradiční vs. moderní porodnictví</h6>
              <p>
                Předně bych ráda upozornila, že tento článek bude mým posouzením a zhodnocením zkušeností, které jsem za svou praxi nasbírala jako nemocniční i jako komunitní PA a měla možnost být součástí různých konferencí, workshopů a seminářů či kurzů.
			  </p>
              <div className="btn-bar">Přečíst více</div>
            </div>
			
            {/* End blog-info */}
          </div>
          {/* End blog-post */}
        </div>
        {/* End .col for blog-5 */}
      </div>
      {/* End .row */}

      {/* Start Modal for Blog-1 */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/proc_chtit_PA.jpg" alt="Proč chtít péči porodní asistentky"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                      Proč chtít péči porodní asistentky
                    </h2>
                    <div className="media">
                      <div className="avatar">
                        <img src="img/testimonial/ja.jpg" alt="thumbnail" />
                      </div>
                      <div className="media-body">
                        <label>Markéta Hanáková</label>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
				  
                     <p>
					Celistvá individuální péče porodní asistentky je poměrně dražší záležitost. Obzvlášť v dnešní těžké době. Proč ji tedy chtít využít?
					</p>
					
					<p>Porodní asistentka  
					<ul>
					<li>- o vás bude pečovat dle vašich potřeb</li>
					<li>- vás kdykoliv vyslechne, poradí a podpoří</li>
					<li>- objektivně zhodnotí váš stav během těhotenství, za porodu i v šestinedělí</li>
					<li>- vás naučí pečovat o miminko</li>
					<li>- podpoří laktaci a ukáže vám různé polohy při kojení</li>
					<li>- naučí partnera, jak pomoci při zvládání porodních bolestí</li>
					<li>- vám pomůže načasovat odjezd do porodnice</li>
					<li>- je vám k dispozici ve dne i v noci, když se přiblíží termín porodu</li>
					</ul>
					</p>
					
					<p>
					Se svou porodní asistentkou se budete cítit bezpečně. Můžete se na ni kdykoliv s čímkoliv obrátit. Máte větší šanci, že porod proběhne bez větších komplikací a bez zbytečného stresu.
					</p>
					
					<p>
					Období těhotenství, porodu a kojení v životě proběhne pouze párkrát. Vzpomínáme na něj však celý život. Vše, co se v něm přihodí, se zaryje hluboko do paměti.
					</p>
					
					<p>
					Znám mnoho žen, babiček - starých, moudrých, které mi vypráví o svých ztrátách, traumatech spojených s vlastními porody. Jejich oči jsou plné smutku, ovšem na konci se rozzáří ta malá jiskřička a jejich tvář rozzáří milý, ale ustaraný úsměv. Mluví o radostech, které jim porod přinesl. Jejich mateřství a věčné neutuchající mateřské lásce.
					Život nikdy nebyl lehký, pokud ale můžeme, proč si ho neulehčit? Až zestárneme, budou nás živit právě jen ty šťastné vzpomínky.
					</p>
					
                  </div>
                  {/* End article content */}
                </article>

                {/* End Article */}

              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-1 */}

      {/* Start Modal for Blog-2 */}
      <Modal
        isOpen={isOpen2}
        onRequestClose={toggleModalTwo}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalTwo}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/kdo_je_PA.jpg" alt="Kdo je porodní asistentka"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                      Kdo je porodní asistentka
                    </h2>
                    <div className="media">
                       <div className="avatar">
                           <img src="img/testimonial/ja.jpg" alt="thumbnail" />
                       </div>
                      <div className="media-body">
                        <label>Markéta Hanáková</label>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p>
                      Stále ještě potkávám lidi, kteří mají mylnou představu o práci porodní asistentky. Spousta z nich si myslí, že je pouze “sestřičkou”, pomáhající u porodu lékaři. Že vlastně nevykonává žádnou odbornou práci, která by byla něčím odlišná od ostatních “nemocničních sester”.
					  Opak je ale pravdou. Porodní asistentka je odbornicí na fyziologické těhotenství, porod a šestinedělí. Poskytuje ošetřovatelskou péči o ženy v podstatě během celého jejich života, nejvíce však během reprodukční fáze. 
					</p>
                    <p>
                      S porodními asistentkami se můžete setkat v porodnicích, v gynekologických ambulancích a na gynekologických odděleních, někdy také na novorozeneckých odděleních. Porodní asistentky pracují ale také i v komunitě. Většinou mívají svá centra, kde vedou předporodní přípravy a různá cvičení. Některé poskytují komplexní individuální péči o ženy a jejich rodiny. Provází je nejen celým těhotenstvím, porodem i šestinedělím, ale je jim oporou často i v dalších letech.
                    </p>
					<p><b>Takže jak je definována porodní asistentka?</b></p>
					
                    <h4>Oficiálně</h4>
                    <p>
                      Dokument “Definice porodní asistentky” (Definition of the Midwife) vznikl již v roce 1972, kdy jej vytvořila Světová zdravotnická organizace (WHO), Mezinárodní konfederace porodních asistentek (ICM) a Mezinárodní federace gynekologů a porodníků (FIGO).
				      Znění definice porodní asistentky bylo upraveno již dvakrát. Současná definice byla přijata v roce 2005.
					</p>
                    <blockquote>
                      <p>
                        Porodní asistentka je osoba, která úspěšně ukončila oficiální vzdělávací program pro porodní asistentky uznávaný v dané zemi, a dosáhla tak požadované kvalifikace pro získání registrace – úředního povolení k výkonu povolání porodní asistentky.
                      </p>
					  <p>
                        Porodní asistentka je uznávána jako plně zodpovědný zdravotnický pracovník, který pracuje jako partner ženy, poskytuje jí potřebnou podporu, péči a radu během těhotenství, porodu a v době poporodní, vede porod na svou vlastní zodpovědnost, poskytuje péči novorozencům a dětem v kojeneckém věku. Tato péče zahrnuje preventivní opatření, podporu normálního porodu, zjišťování komplikací u matky nebo dítěte, zprostředkování přístupu k lékařské péči nebo jiné vhodné pomoci a provedení nezbytných opatření při mimořádné naléhavé situaci.
					  </p>
					  <p>
                        Porodní asistentka má důležitou úlohu ve zdravotním poradenství a vzdělávání nejen žen, ale i v rámci jejich rodin a celých komunit. Tato práce by měla zahrnovat předporodní přípravu a přípravu k rodičovství a může být rozšířena i do oblasti zdraví žen, sexuálního nebo reproduktivního zdraví a péči o dítě.
					  </p>
					  <p>Porodní asistentka může vykonávat svou profesi v jakémkoli prostředí, včetně domácího prostředí, ambulantních zdravotnických zařízení, nemocnic, klinik, nebo zdravotnických středisek.”</p>
                      <p className="blockquote-footer">
                        Definice porodní asistentky. [online]. [cit. 13. března 2022]. Dostupné z WWW {" "}
                        <cite title="Source Title">http://www.pdcap.cz/Texty/PA/DefinicePA.htm</cite>
                      </p>
                    </blockquote>
					
					<h4>V praxi</h4>
					
                    <p>
                      Porodní asistentka je vysokoškolsky vzdělaná odbornice v oblasti porodnictví, gynekologie a neonatologie.
                    </p>
					<p><ul>
					<li>- samostatně pečuje o ženy s fyziologickým - zdravým těhotenstvím, porodem a šestinedělím</li>
					<li>- připraví ženy i jejich rodinu na porod a příchod dítěte</li>
					<li>- poskytuje empatickou psychickou podporu a podporuje celou rodinu v náročných situacích</li>
					<li>- naslouchá a stará se o potřeby celé rodiny</li>
					<li>- ovládá a učí ženu a jejího partnera různé metody zvládání porodní bolesti, polohování</li>
					<li>- rozpoznává u matky, plodu nebo novorozence příznaky patologií nebo rizik, které vyžadují zásah lékaře, a pomáhá mu v případě zásahu, pokud není lékař přítomen, provádí neodkladná opatření v rámci první pomoci</li>
					<li>- podporuje dobré hojení porodního poranění, naučí, jak pečovat o jizvu po císařském řezu</li>
					<li>- poskytuje laktační poradenství a podporuje bonding</li>
					<li>- poskytuje poradenství a ošetřovatelskou péči o plně zdravého novorozence a děti do věku tří let</li>
					</ul></p>
					
					<p>
					Spousta lidí si práci porodní asistentky plete s prací duly. Dula na rozdíl od porodní asistentky nemá zdravotnické vzdělání a tudíž neposkytuje žádné zdravotnické služby. Nedokáže rozlišit odchýlení se od fyziologického průběhu těhotenství, porodu i šestinedělí a nedokáže v případě komplikací odborně zasáhnout. Dula ženě a její rodině poskytuje především psychickou podporu. Předává zkušenosti s péčí o dítě, poskytuje masáže a aromaterapii. Služby duly jsou bez záruky zdravotnické profesionality.
					</p>
					
					
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}

              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-2 */}


     {/* Start Modal for Blog-3 */}
      <Modal
        isOpen={isOpen3}
        onRequestClose={toggleModalThree}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalThree}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/moderni_porodnictvi.jpg" alt="Moderní porodnictví"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                      Moderní porodnictví
                    </h2>
                    <div className="media">
                       <div className="avatar">
                           <img src="img/testimonial/ja.jpg" alt="thumbnail" />
                       </div>
                      <div className="media-body">
                        <label>Markéta Hanáková</label>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p>
                      (Moderní porodnictví je i název odborné knihy prof. Roztočila, který zde, mimo jiné, popisuje porodnictví tak, jak jej známe v dnešní době v porodnicích.)
					</p>
                    <p>
						Moderní porodnictví začíná u diagnostiky těhotenství a končí (možná se budete divit) propuštěním z porodnice. Zahrnuje veškeré těhotenské screeningy, těhotenské poradny, řešení rizikových stavů, porod samotný i časné poporodní období.
					</p>
					<p>
						Je na velice dobré odborné úrovni. Máme výborné diagnostické nástroje, umíme s velikou úspěšností řešit různé problémy. 
					</p>
					<p>
						Ovšem do nedávné doby jsme se na rodičku dívali pouze jako na nástroj pro zrození dítěte. Porodník se na rodičku nedíval jako na bytost s pocity a instinkty. Porod musel postupovat dle daných pravidel, dle tabulek…. Pokud se jen trochu odchýlil, začalo se intervenovat a dítě se nějakým způsobem z rodičky “porodilo”. Věřím, že většina žen má v hlavě stále utkvělou představu našich rodících matek nebo babiček, které rodily ve vykachličkovaných porodních sálech na gynekologickém křesle s nohama v shautech. Před nimi stál sterilně oblečený lékař a vedle ženské sestry, které v případě potřeby pomáhaly rodící ženě tlačit. Pokud žena hlasitě sténala, byla osočena. Jakákoliv její potřeba byla nerelevantní, protože takto se to vše dělalo správně. Lékař provedl vydatnou epiziotomii, dítě “porodil” a stal se velkým hrdinou celé nové rodiny.
					</p>
					<p>
						Naštěstí (troufám si říci) to takto již u nás nefunguje. Porodnictví se vyvíjí v čase a ovlivňuje ho i veřejné mínění a veřejná poptávka.<br />
						V době, kdy máme spousty informací, jsme vzdělanější a můžeme se volně vyjadřovat, je nezbytné, aby se i služby pro veřejnost přizpůsobovaly, pokud chtějí dobře prosperovat.
					</p>
					<p>
						Každý člověk jako bytost ví, co ho dělá šťastným a co mu způsobuje bolest (všeobecně). Taktéž ženy, plánující rodičovství, vědí, co by jim bolest způsobilo. A tak se jí snaží vyhnout. Vyhledávají informace, snaží se nahlédnout do dané problematiky, ověřují si dostupnost svých požadavků. Žádají, aby jejich potřeby byly vyslyšeny.
					</p>
					<p>
						Proto se do dnešního moderního porodnictví dostala spousta novinek, jako je aromaterapie, muzikoterapie, volba porodní polohy, porod do vody, ambulantní porod, otec a další osoba u porodu aj. Porodnice dělají své předporodní kurzy a různá cvičení.
					</p>
					<p>
						Dostávají se do popředí i porodní asistentky, které zde mají velikou úlohu, jelikož se starají o ženu v celém průběhu porodu a lékaři je začínají vnímat jako partnery v péči o ženu. V některých porodnicích je porodní asistentka plně kompetentní v péči o ženu jak v těhotenství, tak i za porodu. Stará se i o první přisátí miminka k prsu a nerušený bonding po porodu. Je odbornicí na kojení.
					</p>
					<p>
						V přívětivějších porodnicích je vidět i průnik tradičního porodnictví, jako je umění porodní asistentky při práci s polohováním ženy a vnímáním polohy dítěte bez použití moderních nástrojů (především ultrazvuku), nápravy špatného postavení dítěte, fytoterapie a s ní spojená napářka, nefarmakologická práce s bolestí, podpora nerušeného bondingu.
					</p>
					<p>
						Dokonce se zde najdou i porodnice spolupracující s komunitními porodními asistentkami, fyzioterapeuty, psychology i dulami.<br />
						Aktuálně se otevírají tzv. Centra porodní asistence, která se snaží fungovat jako porodní domy v jiných zemích….
					</p>
					<p>
						Vše vypadá růžově, že? Vize moderního porodnictví, kde o vás už v době těhotenství může pečovat vaše porodní asistentka, která s vámi bude u porodu v porodnici. Vy porodíte co nejvíce respektujícím způsobem v bezpečném prostředí. PA vám ošetří případné poranění, postará se o vás i o miminko v celém šestinedělí a stane se tak vaším rodinným partnerem.<br />
						A pokud se vyskytnou problémy, víte, že se můžete spolehnout na odbornou, ale stále respektující pomoc lékařů za podpory a péče porodní asistentky.<br />
						Zní to opravdu dobře, ale bohužel toto vše brzdí určité stinné stránky.
					</p>
					<p>
						Největším stinným faktorem je člověk. Konkrétně zde lékař a porodní asistentka podporující zastaralé myšlení typu “ vždycky se to takto dělalo a všichni to přežili”, “ pro jistotu to uděláme takto”, “radši provedeme toto, aby se nic nepřihodilo”…. Dále pak lékaři, kteří nedokáží uznat profesní odbornost a znalosti porodní asistentky - zbrojí proti nim (kompetenční spory, “zápasy o autoritu”), porodní asistentky, které “radši” nechtějí zodpovědnost a jsou v roli “vykonavatelky” intervencí indikovaných lékařem spokojené. Lékaři nepodporující samostatnost porodních asistentek. <br />
						Pak také utkvělé představy a věčný boj na téma porodů doma. Spojování komunitních porodních asistentek jako iniciátorek a podporovatelek této možnosti.<br />
						Na druhou stranu také “agresivita” některých porodních asistentek v propagaci jejich kompetencí a naprostá neschopnost, odmítavost, neochota spolupráce s dalšími odbornostmi. Postrádající sebereflexi.
					</p>
					<p>
						Závěrem tedy mohu napsat, že veškerá péče a vlastně i veškeré bytí je jen o lidech, o tom, koho potkáme, kdo o nás pečuje (mnou věčně omílané klišé) a jakým způsobem dokáže spolupracovat s ostatními účastníky (s úzkou rodinou ženy, s dalšími poskytovateli zdravotní péče, ap.). Nicméně hodnotím pozitivně snahu porodnictví rozvíjet dále, jak po odborné stránce, tak i po té, kde se do zdravotní péče pouští stále více kompetencí porodních asistentek a těhotenství, porodnictví a ženství vůbec se tak stává zase přirozenou součástí našich životů. Nikoliv něčím, co je nepřirozené, co je, chcete-li, nemocí nebo patologií, .….
					</p>
				
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}

              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-3 */}


     {/* Start Modal for Blog-4 */}
      <Modal
        isOpen={isOpen4}
        onRequestClose={toggleModalFour}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalFour}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/tradicni_porodnictvi.jpg" alt="Tradiční porodnictví"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                      Tradiční porodnictví
                    </h2>
                    <div className="media">
                       <div className="avatar">
                           <img src="img/testimonial/ja.jpg" alt="thumbnail" />
                       </div>
                      <div className="media-body">
                        <label>Markéta Hanáková</label>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p>
						Pod pojmem tradiční porodnictví si můžeme představit spousty věcí, například jak se rodilo v dávné minulosti doma. Asistence rodinných příslušníků u porodu a pomoc zkušených žen, které samy porodily děti. Pomoc porodních bab, které přejímaly své umění od starších, zkušených žen, později učené lékaři.<br />
						Nebo porodnictví v porodnicích. Vedení porodu pod přísnou sterilní kontrolou lékařem.
					</p>
                    <p>
						Historie porodnictví je velice zajímavá a jistě jsme ušli do dnešní doby velký kus cesty.
					</p>
					<h4>Jak tedy rozumět pojmu tradičního porodnictví?</h4>
					<p>
						Tradice je obecně zkušenost předávaná z generace na generaci. Je kulturně specifická. (zdroj: wikipedia.org) Tudíž tradiční porodnictví vychází z historie v dané oblasti. <br />
						V ČR by součástí tradičního porodnictví mohly být metody vnějšího vyšetření polohy plodu v děloze, kontinuální péče komunitní porodní asistentky o rodičku, fytoterapie, ale také porodní polohy na zádech nebo v polosedě na gynekologickém křesle vedené lékaři.<br />
						V naší západní kultuře jsme se rychle vyvíjeli, proto najít naši českou tradiční porodní bábu je nemožné. Od dob Marie Terezie se musely porodní babičky vzdělávat a vykonávat zkoušky pod dohledem lékařů. Vzdělávání lékaři trvá dodnes a porodní, dnes již asistentky, si zkušenosti předávají hlavně v nemocniční péči. Komunitní péče vlastně postupně vymizela s přesunem porodů do porodnic kolem 60. let 20 století.
					</p>
					<p>
						V současné době se ale opět probouzí a společně s hladem po tradici k nám proniká tradiční porodnictví z jiných kulturních oblastí, jako je Střední a Jižní Amerika, Nizozemí, Velká Británie nebo Německo. To nám umožňuje se učit staronové poznatky a přejímat tak zkušenosti z jiných částí světa, kde porodnictví funguje na jiné úrovni za jiných podmínek.
					</p>
					<p>
						Společným faktorem je komunitní péče porodních asistentek. Ta by se dala označit za část tradičního porodnictví. Techniky vyšetření, práce s rodinou, rodičkou, plodem jsou pak specifické pro danou oblast. Nejvíce k nám aktuálně proudí tradice mexických porodních asistentek, jejich moudra, jejich zkušenosti a jejich dovednosti. Ukazují nám jiný pohled na porodnictví, učí nás dopřát rodičce pocit bezpečí, sounáležitosti s dítětem a celou rodinou. Možná nám znovu ukazují cestu k tomu být lidštější a vážit si života jako takového.<br />
						Přinesli nám sem jejich tradiční techniku Rebozo, seznamují nás s tzv. přechodovými rituály, uzavíráním kostí po porodu, atp.<br />
						V Německu zase díky iniciativě rodičů a porodních asistentek vznikly porodní domy, kde nízkorizikové ženy rodí pouze za přítomnosti své porodní asistentky fyziologicky, bez zbytečných intervencí.<br />
						V Nizozemí je zase tradicí porod doma s porodní asistentkou.
					</p>
					<p>
						Tradiční porodnictví u nás v ČR by mohlo tedy znamenat návrat péče komunitních porodních asistentek včetně vedení porodů v příjemném prostředí bez zbytečných intervencí. Vyšetřování žen tradičními způsoby (ruce jsou druhé oči), a hlavně trpělivost, empatii a důvěru ve fyziologické procesy rodícího těla ženy a rodícího se nového života.
					</p>
				
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}

              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-4 */}


     {/* Start Modal for Blog-5 */}
      <Modal
        isOpen={isOpen5}
        onRequestClose={toggleModalFive}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalFive}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/tradicni_vs_moderni.jpg" alt="Tradiční vs. moderní porodnictví"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                      Tradiční vs. moderní porodnictví
                    </h2>
                    <div className="media">
                       <div className="avatar">
                           <img src="img/testimonial/ja.jpg" alt="thumbnail" />
                       </div>
                      <div className="media-body">
                        <label>Markéta Hanáková</label>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p>
						Předně bych ráda upozornila, že tento článek bude mým posouzením a zhodnocením zkušeností, které jsem za svou praxi nasbírala jako nemocniční i jako komunitní PA a měla možnost být součástí různých konferencí, workshopů a seminářů či kurzů.<br />
						(Co je tradiční nebo moderní porodnictví najdete v mých dvou předchozích článcích).
					</p>
                    <p>
						S porodnictvím jako takovým jsem se setkala hlavně v nemocnicích. Jelikož má cesta směřovala k lékařství a porodnickému oboru, věnovala jsem velkou část svého profesionálního života studiem porodnictví jednak na Lékařské fakultě a druhak v nemocnici u zkušených PA a lékařů. Naučila jsem se mechanismus porodu, rozeznávat patologie a společně s lékaři je i řešit. Vést indukované, intervenované a medikalizované porody. <br />
						Moc času na přirozenost nezbývalo. Pamatuji si slova mnoha lékařek, že “porod už dávno ve vyspělé společnosti bolet nemusí, může být i urychlen, tak proč se trýznit”. Tenkrát mi to připadalo “normální” a v pořádku.<br />
						Porody jsem vedla sama ve spolupráci se zkušenější PA a lékařem. Co víc jsem si mohla přát, když ve většině porodnic vedli veškeré porody lékaři a PA se tak nesprávně staly “asistentkami lékařů”.<br />
						(Mimochodem v některých porodnicích tento jev stále přetrvává a starší ročníky PA to měnit nehodlají, neboť jim to tak vlastně vyhovuje - nemají odpovědnost.)<br />
						Takto jsem se naučila tzv. moderní porodnictví, které nyní, mimo jiného, v porodnicích nabízí předporodní kurzy, cvičení v těhotenství, přípravu na kojení, v dnešní době také aromaterapii za porodu atp. Pojetí moderního porodnictví se však s dobou mění. A mění se, troufám si říci, že k lepšímu. Více v článku o moderním porodnictví.
					</p>
					<p>
						Já osobně jsem ale velmi náročná, co se vědění a vzdělání týče. Snažila jsem se učit dál… <br />
						O tradičním porodnictví (tím nemyslím tradičním bíle vykachličkovaném, sterilním a na “koze” vedeném porodnictví) jsem se dozvěděla z knih, od kolegyň v komunitní péči, od dul….
					</p>
					<p>
						Na začátku jsem k některým věcem byla velmi skeptická, nevěřila jsem, myslela jsem si, že je to vše takové pozlátko, takové “divadélko”, které má vytvořit dojem něčeho jiného, a tudíž v době všudypřítomného “porodnického násilí”, něčeho vysvobozujícího…. (Nebudu zde ale rozebírat věci, které jsou až příliš extrémní a neslučují se s mým věděním, mou profesní a životní cestou.)
					</p>
					<p>
						Mýlila jsem se, když jsem na vlastní oči spatřila realizaci některých dávno zapomenutých postupů, rad a mohla si je i sama vyzkoušet. “Ono to opravdu funguje”, nevěřila jsem, když jsem si sama vyzkoušela některé dovednosti v praxi u rodiček, které utekly císařskému řezu jen o malý kousek! Které zvládly porodit bez jakýchkoliv farmak na tišení bolestí, bez dirupce vaku blan.<br />
						Byla jsem velmi překvapena a opět se zamilovala do oboru, který mě provází životem.
					</p>
					<p>
						Bohužel v České republice není velká možnost se tradiční techniky v porodnictví učit. Proto je třeba hledat a zajímat se, což vyžaduje nějakou energii, kterou není každý ochotný do své práce dávat. 
					</p>
					<p>
						Moderní a tradiční porodnictví mi moc jednoduše porovnávat nejde. Protože podle mého názoru je třeba znát obojí. Umět pracovat s informacemi, vyhodnocovat jak moderními technikami, tak i těmi tradičními, které nemusí vždy doplňovat pro přesnější výsledek ty moderní. Může tomu být (a u nás porodních bab, nebo chcete-li asistentek, by tomu být mělo) i naopak. Je důležité zapojovat tradiční techniky (vnější vyšetření polohy dítěte v děloze, fytoterapii nebo polohování) a doplňovat je svou bdělostí (vzděláním v moderním lékařském porodnictví).
					</p>
					<p>
						Mohu však s určitou nadějí říci, že dle různých lékařských nebo i multioborových konferencí a viditelné proměně porodnic svítá na lepší časy pro rodičky rodící v porodnicích. <br />
						Porodních asistentek v porodnicích, které chtějí být plně kompetentní a vést fyziologické porody, stále přibývá. Možná díky vlivu komunitních asistentek, které jsou velké strážkyně fyziologie těhotenství, porodu, kojení i šestinedělí, dul, které se specializují na pohodu celé nově se rodící rodiny i porodních asistentek v nemocnicích, které konečně chtějí být těmi strážkyněmi a průvodkyněmi fyziologických porodů, se celé porodnictví mění. <br />
						Rodičky mohou rodit v porodních pokojích, které svým vzhledem přibližují domácí prostředí, mohou rodit v jakékoliv poloze jim je příjemné, v některých porodnicích i ve vaně. Během porodu mohou využívat bylinné napářky, aromaterapie, muzikoterapie, masáží. Některé porodnice přímo spolupracují i s fyzioterapeuty specializujících se na období porodu a po porodu. A některé dokonce spolupracují i s dulami.<br />
						A co se týče péče porodních asistentek v porodnicích? Jak jsem již psala výše, jelikož chtějí být tím, co vystudovaly, jejich péče se mění, zlepšuje. Chtějí se učit věci, které je medicína již nenaučí. Jsou odbornicemi na fyziologické porody. Dokáží včas rozpoznat možné patologie. Chtějí být asistentkami žen za porodu, nikoliv asistentkami lékařů. Chtějí být průvodkyněmi žen za porodu a pečovat o ně svými znalostmi, uměním a i jejich lidskostí a bytím v daném okamžiku. V porodnicích na to ale bohužel nemají takový prostor, jako komunitní porodní asistentka, která tuto péči dokáže plně zajistit nejen během porodu, ale i během těhotenství a šestinedělí.
					</p>
					<p>
						Co však ale stále považuji za velký nedostatek v našem “novém” porodnictví je neochota některých lékařů brát PA jako svou kolegyni, jako svého partnera v péči o ženu. Co se týče srovnání odbornosti v porodnictví, troufám si říci, že znalosti lékaře a PA jsou na velmi podobné úrovni, avšak s tím rozdílem, že lékař léčí - je specialista na patologie, kdežto PA ošetřuje, opečovává, pomáhá - je specialistka na fyziologie (samozřejmě že musí znát i patologie a jejich řešení, ale to je jiné téma).<br />
						Bohužel zde není podporována kontinuální péče porodní asistentkou v celém období ženského života, obzvlášť tedy v reprodukční fázi. Pojišťovny porodní asistentky odmítají zařadit mezi poskytovatele zdravotní péče a žena si tedy veškeré služby PA musí hradit sama. A to má přitom nárok alespoň na pár kontrol v šestinedělí!<br />
						Pokud také fyziologické rodičky mají rodit v porodnicích, je třeba, aby se jim dostala patřičná odborná péče bez jakýchkoliv nátlaků, stresu a urychlování. Tudíž se vyčlenil prostor, kde takto nerušeně mohou rodit i v porodnicích s velkým tlakem na místa.
					</p>
					<p>
						Je stále mnoho věcí, kde bychom se mohli zlepšovat a začít spolu s lékaři, fyzioterapeuty a dulami týmově vycházet. Je třeba také určitého tlaku od žen čerpajících zdravotnické nebo konzultační služby v době jejich celého života, jelikož porodní asistentky jsou odbornicemi na všechny fáze života ženy (od první menstruace po klimakterium). Protože jen tak se může péče změnit ke spokojenosti na všech stranách.
					</p>
				
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}

              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-5 */}

    </>
  );
};

export default Blog;
